import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { customMedia } from '../customMedia'

import { Image } from '../Image'

export interface Props {
  imageSrc: string
  enTitle?: string
  jaTitle: string
  objectPosition?: number
}

export const Jumbotron = (props: Props) => {
  const { imageSrc, enTitle, jaTitle, objectPosition } = props

  return (
    <Container>
      <CoverImageWrapper objectPosition={objectPosition}>
        <img src={imageSrc} alt={imageSrc} />
      </CoverImageWrapper>
      <PageTitle hasEnTitle={enTitle}>
        {enTitle ? (
          <>
            <div>{enTitle}</div>
            <div>{jaTitle}</div>
          </>
        ) : (
          <>
            {jaTitle.split('\n').map((m, i) => <div key={i}>{m}</div>)}
          </>
        )}
      </PageTitle>
    </Container>
  )
}

const SomeComponent = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const CoverImageWrapper = styled.div<{objectPosition?: number}>`
  ${customMedia.lessThan("medium")`
  `}
  ${props => customMedia.greaterThan("medium")`
    img {
      height: 400px;
      object-fit: cover;
      font-family: 'object-fit: cover';
      overflow: hidden;
      ${props.objectPosition && `object-position: 50% ${props.objectPosition}%;`}
      width: 100%;
    }
  `}
`

const PageTitle = styled.div<{hasEnTitle: boolean | string}>`
  ${props => customMedia.lessThan("medium")`
    margin-top: ${props.hasEnTitle ? '-15px' : '-10px'};

    div:nth-child(1) {
      font-family: ${props.hasEnTitle ? `'Quicksand', sans-serif` : `'Kosugi Maru', sans-serif`};
      font-size: ${props.hasEnTitle ? '2.6rem' : '1.8rem'};
      letter-spacing: 0.1em;
      margin-bottom: 5px;
    }
    div:nth-child(2) {
      font-family: 'Kosugi Maru', sans-serif;
      font-size: ${props.hasEnTitle ? '1.2rem' : '1.8rem'};
      letter-spacing: ${props.hasEnTitle ? '0.36em' : '0.1em'};
    }
  `}
  ${props => customMedia.greaterThan("medium")`
    margin-top: -20px;

    div:nth-child(1) {
      font-family: ${props.hasEnTitle ? `'Quicksand', sans-serif` : `'Kosugi Maru', sans-serif`};
      font-size: ${props.hasEnTitle ? '3.6rem' : '3.2rem'};
      letter-spacing: 0.08em;
      margin-bottom: 10px;
    }
    div:nth-child(2) {
      font-family: 'Kosugi Maru', sans-serif;
      font-size: ${props.hasEnTitle ? '1.6rem' : '3.2rem'};
      letter-spacing: 0.08em;
    }
  `}

  color: #0055A4;
  text-align: center;
`