import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../components/common/customMedia'
import { Reveal, Animation } from 'react-genie'
import Bounce from 'react-reveal/Bounce'

import { Layout } from '../components/Layout'
import { SEO } from '../components/common/SEO'
import { Image } from '../components/common/Image'
import { SpBr } from '../components/common/SpBr'
import { PcBr } from '../components/common/PcBr'
import { SpOnly } from '../components/common/SpOnly'
import { PcOnly } from '../components/common/PcOnly'
import { Breadcrumb } from '../components/common/Breadcrumb'
import { Jumbotron } from '../components/common/Jumbotron'
import { Point } from '../components/common/Point'

const NoteMain = require('../images/note_main.jpg')

const IndexPage = () => (
  <Layout>
    <SEO 
      title='　お薬手帳について | シンビ堂'
      description='シンビ堂では患者様に安全に安心にお薬を飲んでいただくために、お薬手帳をお渡ししています。処方内容や副作用歴やアレルギー歴や主な既往症などを一目で分かるので、旅先などでも大活躍です。お薬手帳の活用をおすすめしています。'
      keywords={['お薬手帳','軽井沢','シンビ堂','調剤薬局','薬局']}
    />
    <h1>お薬手帳 軽井沢　薬局 調剤薬局 | シンビ堂</h1>

    <Container>
      <Jumbotron imageSrc={NoteMain} jaTitle={`お薬手帳を\n活用しよう`} objectPosition={70} />
      <BreadcrumbWrapper>
        <Breadcrumb color='blue' current='お薬手帳を活用しよう' />
      </BreadcrumbWrapper>
      <Reveal delay={500} animation={Animation.FadeInUp}>
        <SubTitle>シンビ堂薬局では、患者様に安全に安心してお薬を飲んでいただけるように<PcBr />お薬手帳をお渡ししています。</SubTitle>
      </Reveal>
      <Point color='pink' title='POINT1' subTitle={`お薬手帳って\nどんなもの？`}>
        <Point1Container>
          <Point1ImageWrapper><Image path='note.jpg' /></Point1ImageWrapper>
          <Point1DescriptionContainer>
            
            <Reveal delay={2000} animation={Animation.FadeInUp}>
              <Bounce delay={2100}>
                <Point1Description>
                  <Point1DescriptionTitle>
                    <Point1DescriptionPlusImageWrapper>
                      <Image path='note_plus@2x.png' />
                    </Point1DescriptionPlusImageWrapper>
                    処方内容
                  </Point1DescriptionTitle>
                  <Point1DescriptionDetail>
                    調剤日/調剤薬局名/処方せん発行医療機関名/薬剤名/薬剤の用量・用法/日数/ジェネリック医薬品を使用しているか
                  </Point1DescriptionDetail>
                </Point1Description>
              </Bounce>
            </Reveal>

            <Reveal delay={2100} animation={Animation.FadeInUp}>
              <Bounce delay={2200}>
                <Point1Description>
                  <Point1DescriptionTitle>
                    <Point1DescriptionPlusImageWrapper>
                      <Image path='note_plus@2x.png' />
                    </Point1DescriptionPlusImageWrapper>
                    副作用歴
                  </Point1DescriptionTitle>
                </Point1Description>
              </Bounce>
            </Reveal>

            <Reveal delay={2200} animation={Animation.FadeInUp}>
              <Bounce delay={2300}>
                <Point1Description>
                  <Point1DescriptionTitle>
                    <Point1DescriptionPlusImageWrapper>
                      <Image path='note_plus@2x.png' />
                    </Point1DescriptionPlusImageWrapper>
                    アレルギー歴
                  </Point1DescriptionTitle>
                </Point1Description>
              </Bounce>
            </Reveal>

            <Reveal delay={2400} animation={Animation.FadeInUp}>
              <Bounce delay={2500}>
                <Point1Description>
                  <Point1DescriptionTitle>
                    <Point1DescriptionPlusImageWrapper>
                      <Image path='note_plus@2x.png' />
                    </Point1DescriptionPlusImageWrapper>
                      主な既往症
                  </Point1DescriptionTitle>
                  <Point1DescriptionDetail>
                    自分の使っている薬の名前・量・日数・使用法などの処方内容や、副作用歴、既往症、アレルギー歴などを記録できる手帳です。初めて受診する病院でも、この「お薬手帳」を見せるだけで、あなたお薬のことをわかってもらえます。
                  </Point1DescriptionDetail>
                </Point1Description>
              </Bounce>
            </Reveal>

          </Point1DescriptionContainer>
        </Point1Container>
      </Point>

      <Point color='white' title='POINT2' subTitle={`お薬手帳を使う\nメリット`}>
        <Point2Container>
          <Reveal delay={2000} animation={Animation.FadeInUp}>
            <Point2Item1>
              <Point2Item1ImageWrapper><Image path='note_tablet@2x.png' /></Point2Item1ImageWrapper>
              <Point2ItemDescription>薬の重複やよくない<br />飲み合わせを防止出来ます</Point2ItemDescription>
            </Point2Item1>
          </Reveal>
          <Reveal delay={2500} animation={Animation.FadeInUp}>
            <Point2Item2>
              <Point2Item2ImageWrapper><Image path='note_doctor@2x.png' /></Point2Item2ImageWrapper>
              <Point2ItemDescription>副作用歴、アレルギー、<br />過去にかかった病気などの<br />情報を伝えることができます</Point2ItemDescription>
            </Point2Item2>            
          </Reveal>
          <Reveal delay={3000} animation={Animation.FadeInUp}>
            <Point2Item3>
              <Point2Item3ImageWrapper><Image path='note_trip@2x.png' /></Point2Item3ImageWrapper>
              <Point2ItemDescription>旅行時や災害時、救急の時等でも、<br />自分が飲んでいる薬の情報を<br />正確に伝えられます</Point2ItemDescription>
            </Point2Item3>
          </Reveal>
        </Point2Container>
      </Point>

      <Point color='pink' title='POINT3' subTitle={`お薬手帳を\n活用しましょう`}>
        <Point3Container>
          <Reveal delay={2000} animation={Animation.FadeInUp}>
            <Point3Item1>
              <Point3Item1ImageWrapper><Image path='note_1@2x.png' /></Point3Item1ImageWrapper>
              <Point3ItemDescription>
                <div>
                  <Point3ItemDescriptionTitle>常に持ち歩きましょう</Point3ItemDescriptionTitle>
                  <Point3ItemDescriptionDetail>旅行先の急な病気、外出時の事故、災害時などの緊急時にも、使用しているお薬が分かり、スムーズに対処できます。</Point3ItemDescriptionDetail>
                </div>
              </Point3ItemDescription>
            </Point3Item1>
          </Reveal>

          <Reveal delay={2300} animation={Animation.FadeInUp}>
            <Point3Item2>
              <Point3Item2ImageWrapper><Image path='note_2@2x.png' /></Point3Item2ImageWrapper>
              <Point3ItemDescription>
                <div>
                  <Point3ItemDescriptionTitle>1冊にまとめましょう</Point3ItemDescriptionTitle>
                  <Point3ItemDescriptionDetail>服用薬の記載漏れなどを防ぎ、より正確な記録になります。複数の医療機関で使用する場合も、情報の共有ができるため、的確な治療と安全なお薬の使用に繋がります。</Point3ItemDescriptionDetail>
                </div>
              </Point3ItemDescription>
            </Point3Item2>
          </Reveal>

          <Reveal delay={2600} animation={Animation.FadeInUp}>
            <Point3Item3>
              <Point3Item3ImageWrapper><Image path='note_3@2x.png' /></Point3Item3ImageWrapper>
              <Point3ItemDescription>
                <div>
                  <Point3ItemDescriptionTitle>気になる事を書いておきましょう</Point3ItemDescriptionTitle>
                  <Point3ItemDescriptionDetail>体調の変化や、気になる事、医師や薬剤師に相談したい事を書いておきましょう。</Point3ItemDescriptionDetail>
                </div>
              </Point3ItemDescription>
            </Point3Item3>
          </Reveal>
        </Point3Container>
      </Point>

      <QaContainer>
        <QaContent>
          <Reveal delay={1000} animation={Animation.FadeInUp}>
            <QaTitle>お薬手帳Q&A</QaTitle>
          </Reveal>
          
          <QaWrapper>
            <Reveal delay={1200} animation={Animation.FadeInUp}>
              <QaQuestion>
                <QIcon>Q.</QIcon><div>お薬手帳はいつ出すの？</div>
              </QaQuestion>
            </Reveal>

            <Reveal delay={1400} animation={Animation.FadeInUp}>
              <QaAnswer>
                <AIcon>A.</AIcon><div>薬局の受付時に処方せんと一緒にお出し下さい。</div>
              </QaAnswer>
            </Reveal>
          </QaWrapper>

          <QaWrapper>
            <Reveal delay={1600} animation={Animation.FadeInUp}>
              <QaQuestion>
                <QIcon>Q.</QIcon><div>お薬手帳を作った薬局以外の他の薬局でも使えるの？</div>
              </QaQuestion>
            </Reveal>
            <Reveal delay={1800} animation={Animation.FadeInUp}>
              <QaAnswer>
                <AIcon>A.</AIcon><div>使えます。お薬手帳は、あなたの手帳です。病院ごと、薬局ごとに分けるのではなく、全て１冊で管理して記入してもらいましょう。</div>
              </QaAnswer>
            </Reveal>
          </QaWrapper>

          <QaWrapper>
            <Reveal delay={2000} animation={Animation.FadeInUp}>
              <QaQuestion>
                <QIcon>Q.</QIcon><div>いつも同じ薬しか飲まなくても、お薬手帳は必要なの？</div>
              </QaQuestion>
            </Reveal>
            <Reveal delay={2200} animation={Animation.FadeInUp}>
              <QaAnswer>
                <AIcon>A.</AIcon><div>同じお薬を継続して服用していることを記録しておけば、他の医療機関を受診する時、薬局で薬を購入する時などに、いつもの薬といっしょに飲んでもよいかをチェックしてもらえます。</div>
              </QaAnswer>
            </Reveal>
          </QaWrapper>
        </QaContent>
      </QaContainer>
    </Container>
  </Layout>
)

const Container = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const BreadcrumbWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 15px 0 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
    top: 590px;
    left: 100px;
    position: absolute;
  `}
`

const SubTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    padding: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    line-height: 30px;
    letter-spacing: 0.14em;
    margin: 40px 0 60px;
    text-align: center;
  `}

  color: #00559F;
`


const Point1Container = styled.div`
  ${customMedia.lessThan("medium")`
    margin-bottom: 25px;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    margin: 35px auto 60px;
    width: 800px;
  `}
`

const Point1ImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    margin: 20px auto;
    width: 130px;
  `}
  ${customMedia.greaterThan("medium")`
    width: 283px;
  `}
`

const Point1DescriptionContainer = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`  
    margin-left: 20px;
    width: 500px;
  `}

  color: #0055A1;
`

const Point1Description = styled.div`
  ${customMedia.lessThan("medium")`
    border-radius: 5px;
    margin-bottom: 5px;
    padding: 15px;
  `}
  ${customMedia.greaterThan("medium")`
    border-radius: 7px;
    margin-bottom: 5px;
    padding: 15px;
  `}

  background-color: #fff;
`

const Point1DescriptionPlusImageWrapper = styled.div`
  ${customMedia.lessThan("medium")`
    display: inline-block;
    margin-right: 8px;
    width: 12px;
  `}
  ${customMedia.greaterThan("medium")`
    display: inline-block;
    margin-right: 8px;
    width: 16px;
  `}
`

const Point1DescriptionTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
  `}

  font-weight: bold;
`

const Point1DescriptionDetail = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    margin-top: 4px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 4px;
  `}

  letter-spacing: 0.04em;
`

const Point2Container = styled.div`
  ${customMedia.lessThan("medium")`
    margin-bottom: 25px;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin: 40px auto 50px;
    width: 800px;
  `}
`

const Point2Item1 = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 30px;
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Point2Item1ImageWrapper = styled.div`
  margin: 0 auto;
  width: 123px;
`

const Point2Item2 = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 30px;
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Point2Item2ImageWrapper = styled.div`
  margin: 0 auto;
  width: 127px;
`

const Point2Item3 = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 30px;
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

const Point2Item3ImageWrapper = styled.div`
  margin: 0 auto;
  width: 166px;
`

const Point2ItemDescription = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    letter-spacing: 0.04em;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    letter-spacing: 0.04em;
  `}

  color: #00559F;
  font-weight: bold;
  margin-top: 12px;
  text-align: center;
`

const Point3Container = styled.div`
  ${customMedia.lessThan("medium")`
    margin-bottom: 25px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 40px auto 50px;
    width: 700px;
  `}
`

const Point3Item1 = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  `}
`

const Point3Item1ImageWrapper = styled.div`
  margin: 0 auto;
  width: 147px;
`

const Point3Item2 = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
  `}
`

const Point3Item2ImageWrapper = styled.div`
  margin: 0 auto;
  width: 79px;
`

const Point3Item3 = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    margin-top: 30px;
  `}
`

const Point3Item3ImageWrapper = styled.div`
  margin: 0 auto;
  width: 141px;
`

const Point3ItemDescription = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 500px;
  `}

  color: #fff;
  letter-spacing: 0.04em;
  margin-top: 12px;
`

const Point3ItemDescriptionTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2rem;
    font-weight: bold;
  `}
`

const Point3ItemDescriptionDetail = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    margin-top: 12px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 15px;
  `}
`

const QaContainer = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}

  background-color: #00559F;
  overflow: hidden;
`

const QaContent = styled.div`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
    margin-bottom: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    margin: 0 auto 60px;
    width: 635px;
  `}

  color #fff;
`

const QaTitle = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.8rem;
    letter-spacing: 0.12em;
    margin-top: 25px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.8rem;
    letter-spacing: 0.2em;
    margin-top: 50px;
  `}

  font-weight: bold;
  text-align: center;
`

const QaWrapper = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
    margin-top: 35px;
  `}
`

const QaQuestion = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.4rem;
    margin-top: 30px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
  `}

  display: -webkit-flex;
  display: flex;
  font-weight: bold;
  letter-spacing: 0.04em;
`

const QIcon = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.8rem;
    margin-right: 5px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2rem;
    margin-right: 10px;
  `}

  font-family: 'Quicksand', sans-serif;
`

const QaAnswer = styled.div`
  ${customMedia.lessThan("medium")`
    margin-top: 10px;
    font-size: 1.4rem;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 1.6rem;
    margin-top: 20px;
  `}

  display: -webkit-flex;
  display: flex;
  letter-spacing: 0.04em;
`

const AIcon = styled.div`
  ${customMedia.lessThan("medium")`
    font-size: 1.8rem;
    margin-right: 5px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2rem;
    margin-right: 10px;
  `}

  color: #CA2F68;
  font-family: 'Quicksand', sans-serif;
`


const SomeComponent = styled.div`
  ${customMedia.lessThan("medium")`
  `}
  ${customMedia.greaterThan("medium")`
  `}
`

export default IndexPage
