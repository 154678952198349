import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { customMedia } from '../../common/customMedia'
import { PcOnly } from '../../common/PcOnly'
import { SpOnly } from '../../common/SpOnly'
import { Reveal, Animation } from 'react-genie'

export interface Props {
  color: string
  title: string
  subTitle: string
}

export interface OwnProps {
  children: ReactNode
} 

export const Point = (props: Props & OwnProps) => {
  const {color, title, subTitle } = props
  const { children } = props
  
  return (
    <Container color={color}>
      <SpOnly>
        <Reveal delay={1000} animation={Animation.FadeIn}>
          <Title color={color}>{title.split('\n').map((m, i) => <div key={i}>{m}</div>)}</Title>
        </Reveal>
        <Reveal delay={1500} animation={Animation.FadeIn}>
          <SubTitle color={color}>{subTitle.split('\n').map((m, i) => <div key={i}>{m}</div>)}</SubTitle>
        </Reveal>
      </SpOnly>
      <PcOnly>
        <Reveal delay={1000} animation={Animation.FadeIn}>
          <Title color={color}>{title.replace('\n', '')}</Title>
        </Reveal>
        <Reveal delay={1500} animation={Animation.FadeIn}>
          <SubTitle color={color}>{subTitle.replace('\n', '')}</SubTitle>
        </Reveal>
      </PcOnly>
      {children}
    </Container>
  )
}

const Container = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
    padding: 0 20px;
  `}
  ${customMedia.greaterThan("medium")`
  `}

  background-color: ${props => props.color === 'pink' ? '#CA2F68'
    : props.color === 'white' ? '#fff' 
    : '#fff'
  };
  overflow: hidden;
`

const Title = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
    font-size: 2.6rem;
    margin-top: 20px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 3.6rem;
    margin-top: 63px;
  `}

  color: ${props => props.color === 'pink' ? '#fff'
    : props.color === 'white' ? '#CA2F68' 
    : '#000'
  };
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
`

const SubTitle = styled.div<{color: string}>`
  ${customMedia.lessThan("medium")`
    font-size: 1.8rem;
    letter-spacing: 0.12em;
    margin-top: 10px;
  `}
  ${customMedia.greaterThan("medium")`
    font-size: 2.8rem;
    letter-spacing: 0.2em;
    margin-top: 20px;
  `}

  color: ${props => props.color === 'pink' ? '#fff'
    : props.color === 'white' ? '#00559F' 
    : '#000'
  };
  font-weight: bold;
  text-align: center;
`